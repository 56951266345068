<template>
  <div>
    <b-row v-if="!movementBulk.id">
      <b-col sm="12">
        <RadioGroup title="Tipo" field="type" :formName="formName" :options="[
          { text: 'Saída', value: 1 },
          { text: 'Retorno', value: 2 },
        ]" v-model="movementBulk.type" />
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12">
        <InputText title="Identificação" field="name" :formName="formName" :required="true" :maxLength="50"
          v-model="movementBulk.name" :markFormDirty="true" />
      </b-col>
    </b-row>
    <div v-if="movementBulk.type == 1">
      <div class="div-tag">
        <InputTag title="Digite o Nº de Locação e pressione Enter" :initialValue="movementBulk._rentsLinked"
          v-model="movementBulk.rentsLinked" />
      </div>
      <div v-if="movementBulk._rentsLinked.length == 0">
        <b-row>
          <b-col sm="12">
            <Select title="Cliente (Nome ou CNPJ/CPF)" field="customer" :formName="formName"
              url="api/v1/crm/customer/select-all" v-model="movementBulk.customer"
              nameNewRegister="customerSupplierOrCollaboratorCreate" titleNewRegister="Cliente" :widthNewRegister="1200"
              :heightNewRegister="250">

              <CustomerSupplierCreate formName="customerSupplierCreate" :_hideSideBar="false"
                v-model="movementBulk.customer" />
            </Select>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="2">
            <DateTime title="Saída" field="deliveryDateHour" format="DD/MM/YYYY HH:mm" type="dateTime" placeholder
              :formName="formName" v-model="movementBulk.deliveryDateHour" />
          </b-col>
          <b-col sm="2">
            <DateTime title="Retorno" field="devolutionDateHour" format="DD/MM/YYYY HH:mm" type="dateTime" placeholder
              :formName="formName" v-model="movementBulk.devolutionDateHour" />
          </b-col>
        </b-row>
      </div>
    </div>
    <SaveCancel :showFixed="true" :formName="formName" :marginBottom="15" />
  </div>
</template>

<script>


import InputText from "@nixweb/nixloc-ui/src/component/forms/InputText";
import InputTag from "@nixweb/nixloc-ui/src/component/forms/InputTag";
import RadioGroup from "@nixweb/nixloc-ui/src/component/forms/RadioGroup.vue";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import DateTime from "@nixweb/nixloc-ui/src/component/forms/DateTime";
import Select from "@nixweb/nixloc-ui/src/component/forms/Select";
import SaveCancel from "@nixweb/nixloc-ui/src/component/shared/SaveCancel";

import CustomerSupplierCreate from '../../shared/customer-supplier/CustomerSupplierCreate.vue'

import MovementBulk from "@/components/modules/operational/movement-bulk/MovementBulk.js";

import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "MovementBulkCreateUpdate",
  props: {
    data: Object,
  },
  components: { InputText, RadioGroup, Button, DateTime, Select, CustomerSupplierCreate, InputTag, SaveCancel },
  data() {
    return {
      formName: "movementBulkCreateUpdate",
      movementBulk: new MovementBulk(),
      urlCreate: "/api/v1/operational/movement-bulk/create",
      urlUpdate: "/api/v1/operational/movement-bulk/update",
    };
  },
  mounted() {
    this.update(this.data);
  },
  computed: {
    ...mapGetters("validation", ["isFormValid"]),
    ...mapGetters("generic", ["event"]),
  },
  methods: {
    ...mapActions("generic", ["postApi", "putApi"]),
    ...mapMutations("generic", [
      "addEvent",
      "removeLoading",
      "removeEvent",
      "hideModal",
      "hideSideBar",
      "hideVodal",
    ]),
    ...mapMutations("validation", ["resetValidation"]),
    saveMovementBulk() {
      if (this.movementBulk.id) {
        let params = { url: this.urlUpdate, obj: this.movementBulk };
        this.putApi(params).then((response) => {
          this.addEvent({ name: "movementBulkUpdated" });
          if (response.success) this.hideSideBar();
          this.removeLoading(["saveSaveCancel"]);
        });
      } else {
        let params = { url: this.urlCreate, obj: this.movementBulk };
        this.postApi(params).then((response) => {
          if (response.success) {
            this.$router.push({
              name: "movementBulkUpdate",
              params: { id: response.content.id },
            });
            this.hideSideBar();
          }
          this.removeLoading(["saveSaveCancel"]);
        });
      }
    },
    update(data) {
      this.movementBulk = new MovementBulk();
      this.movementBulk.update(data);
      this.removeLoading([data.id]);
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "movementBulkCreate") {
          this.resetValidation(this.formName);
          this.movementBulk = new MovementBulk();
        }
        if (event.name == "movementBulkUpdate") this.update(event.data);

        if (event.name == "saveSaveCancel") this.saveMovementBulk();
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.div-tag {
  margin-bottom: 10px;
}
</style>