export default class MovementBulk {
    constructor() {
        this.id = "";
        this.name = "";
        this.type = 1;
        this.deliveryDateHour = "";
        this.devolutionDateHour = "";
        this.customer = { id: "", content: "" };
        this.rentsLinked = [];
        this._rentsLinked = [];
    }
    update(data) {
        

        this.id = data.movementBulk.id;
        this.name = data.movementBulk.name;
        this.type = data.movementBulk.type;
        this.deliveryDateHour = data.deliveryDateHourStr;
        this.devolutionDateHour = data.devolutionDateHourStr;
        if (data.customer)
            this.customer = { id: data.customer.id, content: data.customer.person.companyName + " (" + data.customer.person.tradeName + ")" };

        this._rentsLinked = data.rentsLinked;
    }
}